<template>
    <div class="examination">
        <div class="top">
            <div class="left" @click="backHome">
                <i class="el-icon-back"></i>
            </div>
            <div class="center">
                <span>{{ gaugeName }}</span>
            </div>
            <div class="right">
                <!-- <span>{{ userInfo.nickname }}</span> -->
                <!-- <i class="el-icon-s-fold" @click="openMenu"></i> -->
            </div>
        </div>
        <!-- 开始做题 -->
        <div class="region">
            <div class="mainContent">
                <div class="rg submit" v-if="submitStatus">
                    <div class="img">
                        <img src="../../assets/home/submit.png" alt="">
                        <p>提交成功</p>
                    </div>
                    <div class="submitBtn">
                        <span @click="back">返回</span>
                        <span class="look" v-if="lookStatus" @click="toLook">查看报告</span>
                        <!-- <button @click="back">返回</button>
                    <button>查看报告</button> -->
                    </div>
                </div>
                <div class="rg" v-loading="loading" v-else>
                    <div class="progress">
                        <span class="one">第{{ paging.p }}题</span>
                        <!-- :max="tableData.length" -->
                        <el-slider v-model="paging.p" :max="tableData.length" :min="1"></el-slider>
                        <span class="two">第{{ tableData.length }}题</span>
                    </div>
                    <!-- <span>{{time}}</span> -->
                    <!-- <div>
                    <span>{{timer}}</span>
                    <button @click="timeStop">结束</button>
                </div> -->
                    <div class="examinationContent"
                        v-for="item in tableData.slice((paging.p - 1) * paging.ps, paging.p * paging.ps)"
                        :key="item.problem.id">
                        <span v-if="item.problem.isAnswer === true" style="color:red">*</span>
                        <!-- <span>{{time}}</span> -->
                        <span class="question">{{ paging.p }}、{{ item.problem.name }}</span><span class="question"
                            v-if="item.problem.type === 1">(单选)</span>
                        <span class="question" v-else-if="item.problem.type === 2">(多选)</span>
                        <span class="question" v-else-if="item.problem.type === 3">(图片单选)</span>
                        <span class="question" v-else-if="item.problem.type === 4">(图片多选)</span>
                        <div v-if="item.problem.url" class="">
                            <img :src="item.problem.url" alt="">
                        </div>
                        <div class="answer" v-for="ele in item.answerList" :key="ele.id">
                            <span @click="choiceAnswer(ele, item)" :class="ele.selected ? 'choose' : ''">{{ ele.name
                            }}</span>
                            <div v-if="ele.url" class="">
                                <img :src="ele.url" alt="">
                            </div>
                        </div>
                        <div class="submitBtn">
                            <span @click="lastQuestion">上一题</span>
                            <span @click="nextQuestion(item)" class="look" v-if="paging.p < tableData.length">下一题</span>
                            <span v-else class="look" @click="submit(item)">提交</span>
                            <!-- <button @click="lastQuestion">上一题</button>
                            <button @click="nextQuestion(item)" v-if="paging.p < tableData.length">下一题</button>
                            <button v-else @click="submit">提交</button> -->
                        </div>
                    </div>
                    <!-- <div class="btn">
                        <button @click="lastQuestion">上一题</button>
                        <button @click="nextQuestion" v-if="paging.p < tableData.length">下一题</button>
                        <button v-else @click="submit">提交</button>
                    </div> -->
                    <!-- <div class="block">
                    <el-pagination background layout="prev, pager, next" @current-change="handleCurrentChange"
                        :current-page="paging.p" :page-size="paging.ps" :total="paging.total">
                    </el-pagination>
                </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getProblem, submitProblem, submitProblemOne, submitAll } from '@/api/evaluation'
import { getGauge } from '@/api/evaluation'
import { getMaxListeners } from 'process';
export default {
    data() {
        return {
            val: 20,
            gaugeId: null, // 量表id
            planId: null, // 计划id
            userId: null, // 用户id
            time: 0, //做题时长
            timer: null, // 计时器
            tableData: [],
            paging: {
                p: 1,
                ps: 1
            },
            loading: false,
            submitStatus: false,
            type: null,
            planInfo: null, // 计划信息
            gaugeInfo: null, // 量表信息
            lookStatus: false, // 查看报告的状态
            gaugeName: null, //量表名称
            nowId: null, // 当前做题的id
            ReportId: null, // 提交成功后保存的报告id
            isReport: null // 是否可以查看报告
        }
    },
    methods: {
        // 获取题目 未合并量表 根据量表id获取题目
        async getProblemList() {
            this.timeStart();
            const res = await getProblem({ gaugeId: this.gaugeInfo.id, type: this.gaugeInfo.type, planId: this.gaugeInfo.planId, isRandom: this.gaugeInfo.isRandom,id:this.userId });
            console.log(res);
            if (res.data.time) {
                this.time = res.data.time;
            }
            if (res.code === 200) {
                if (this.gaugeInfo.type === 1) {
                    this.tableData = res.data;
                } else {
                    this.tableData = res.data.problemVOList;
                    this.nowId = res.data.id;
                }

                // 给每题添加一个状态用于判断是否把题做完了
                this.tableData.forEach(item => {
                    item.problem.finished = false;
                    item.answerList.forEach(ele => {
                        if (ele.selected === true) {
                            item.problem.finished = true;
                        }
                    })
                })
                console.log(this.tableData);
                let arr = [];
                this.tableData.forEach(item => {
                    if (item.problem.finished === false) {
                        arr.push(item);
                    }
                })
                console.log(arr);
                console.log(this.gaugeInfo.isRandom)
                if (this.gaugeInfo.isRandom === 0) {
                    if (arr.length > 0) {
                        this.paging.p = Number(arr[0].problem.orderNum);
                    }
                    else {
                        this.paging.p = arr[arr.length - 1].problem.orderNum;
                    }
                } else {
                    this.paging.p = 1;
                }
                // this.paging.total = res.data.length;
            }
        },
        // 根据计划id获得题目，合并量表
        async getList(val) {
            const res = await getGauge({ isMerge: val.isMerge, planId: val.id, isFirst: val.isFirst,id:this.userId });
            console.log(res);
            if (res.code === 200) {
                this.timeStart();
                if (res.data.time) {
                    this.time = res.data.time;
                }
                if (res.data.isRandom === 1) {
                    this.tableData = res.data.problemVOSet;
                } else {
                    this.tableData = res.data.problemVOList;
                }
                // 给每题添加一个状态用于判断是否把题做完了
                this.tableData.forEach(item => {
                    item.problem.finished = false;
                    item.answerList.forEach(ele => {
                        if (ele.selected === true) {
                            item.problem.finished = true;
                        }
                    })
                })
                console.log(this.tableData);
                let arr = [];
                this.tableData.forEach(item => {
                    if (item.problem.finished === false) {
                        arr.push(item);
                    }
                })
                // 如果是继续答题，跳转到上次答题的地方
                console.log(arr);

                if (this.planInfo.isRandom === 0) {
                    if (arr.length > 0) {
                        this.paging.p = Number(arr[0].problem.orderNum);
                    } else {
                        this.paging.p = arr[arr.length - 1].problem.orderNum;
                    }
                } else {
                    this.paging.p = 1;
                }
            }
        },
        // 上一题
        lastQuestion() {
            if (this.paging.p > 1) {
                this.paging.p--;
            } else {
                this.$message({
                    showClose: true,
                    message: '已是第一题！'
                });
            }
        },
        // 下一题
        nextQuestion(val) {
            console.log(val);
            // 先判断该题是否是必答题
            if (val.problem.isAnswer === true) {
                // 如果是必答题，并且已经做答，那么可以切换到下一题
                if (val.problem.finished === true && val.problem.isAnswer === true) {
                    if (this.paging.p < this.tableData.length) {
                        this.paging.p++;
                    } else {
                        this.$message({
                            showClose: true,
                            message: '已是最后一题！'
                        });
                    }
                } else { // 如果是必答题，但是没有作答，那么提示用户选择答案
                    this.$message({
                        showClose: true,
                        message: '请选择答案！'
                    });
                }

            } else { // 如果不是必答题，那么可以直接跳转到下一题
                this.paging.p++;
            }

        },
        // 选择答案
        async choiceAnswer(val, val2) {
            this.loading = true;
            // console.log(this.time)
            // console.log(val);
            // console.log(val2);
            // console.log(this.paging.p)
            let info = {
                answerDTOS: [
                    {
                        answerId: null,
                        score: null
                    }],
                gaugeId: null,
                planId: null,
                problemId: null,
                time: null,
                userId:  this.userId
                // isMerge: null
            }
            // val2.problem.finished = false;
            // 先判断是单选还是多选 如果是单选进入下一层判断
            if (val2.problem.type === 1 || val2.problem.type === 3) {
                // 如果是单选，把当前答案改为选中其他则为未选中。
                val.selected = true;
                val2.problem.finished = true;
                val2.answerList.forEach(item => {
                    if (item.id !== val.id) {
                        item.selected = false;
                    }
                });
                // 判断用户是否选中，当前选项是否为必答
                console.log(val2.problem.finished)
                // val2.problem.isAnswer === true &&
                if ( this.paging.p <= this.tableData.length) {

                    info.answerDTOS[0].answerId = val.id;
                    info.answerDTOS[0].score = val.score;
                    info.gaugeId = val2.problem.gaugeId;
                    info.planId = this.planInfo.id;
                    // info.isMerge = this.planInfo.isMerge;
                    info.problemId = val2.problem.id;
                    info.time = this.time;
                    const res = await submitProblemOne(JSON.stringify(info));
                    console.log(res);
                    if (res.code === 200) {
                        this.loading = false; 
                        console.log(this.paging.p)
                        this.paging.p++;
                        this.ReportId = res.data.id;
                        this.isReport = res.data.isReport;
                       
                    }

                }
            } else {
                val.selected = true;
                val2.problem.finished = true;
                // console.log(val2.answerList)
                let arr = [];
                val2.answerList.forEach(item => {
                    if (item.selected === true) {
                        arr.push(item)
                    }
                });
                console.log(arr)

                arr.forEach((item, index) => {
                    info.answerDTOS[index] = {
                        answerId: item.id,
                        score: item.score
                    }
                });
                info.gaugeId = val2.problem.gaugeId;
                info.planId = this.planInfo.id;
                // info.isMerge = this.planInfo.isMerge;
                info.problemId = val2.problem.id;
                info.time = this.time;
                const res = await submitProblemOne(JSON.stringify(info));
                console.log(res);
                if (res.code === 200) {
                    this.loading = false; 
                    console.log(this.paging.p)
                    // this.paging.p++;
                    this.ReportId = res.data.id;
                    this.isReport = res.data.isReport;
                }
            }
            // console.log(info)
        },
        // 点击提交
        async submit(val) {
            console.log(val)
            this.timeStop();
            
            let info  = {}
           let  gaugeInfoId = null;
            if(this.planInfo.isMerge !== 1) {
                gaugeInfoId =  this.gaugeInfo.id
            }
            // if(this.planInfo)
            if(Number(localStorage.getItem('roleId')) === 2) {
               info =  {gaugeId: gaugeInfoId, planId: this.planInfo.id, isMerge: this.planInfo.isMerge, time: this.time,  userId: this.userId, operatorId: JSON.parse(localStorage.getItem('userInfo')).id}
            } else {
                info = {gaugeId: gaugeInfoId, planId: this.planInfo.id, isMerge: this.planInfo.isMerge, time: this.time,  userId: this.userId}
            }
           
            // 先判断是否所有的已经做完
            this.loading = true;
            // 判断是否把题目答完
            let arr = [];
            this.tableData.forEach(item => {
                if (item.problem.finished === false && item.problem.isAnswer === true) {
                    arr.push(item);
                }
            });
            // 如果该数组小于1说明所有的都已经答完
            if (arr.length < 1) {
                const res = await submitAll(JSON.stringify(info));
                console.log(res)
                if (res.code === 200) {
                    setTimeout(() => {
                        this.ReportId = res.data.id;
                    this.loading = false;
                    this.submitStatus = true;
                }, 1000);
                // 判断该计划是否开放报告
                if (res.data.isReport === 1) {
                    this.lookStatus = true;
                } else {
                    this.lookStatus = false;
                }
                }
                
            } else {
                setTimeout(() => {
                    this.loading = false;
                    // this.submitStatus = true;
                }, 1000);
                this.$message({
                    showClose: true,
                    message: '您有必答题未选择！'
                });
            }
        },
        timeStart() {
            this.time = 0;
            this.timer = setInterval(() => {
                this.time++;
            }, 1000);
        },
        timeStop() {
            clearInterval(this.timer);
        },
        // 提交后点击返回
        back() {
            // 判断是否为合并量表，不是的话返回量表页面
            // this.$router.push('/home/evaluation')
            this.$router.push(`${localStorage.getItem('path')}`)
        },
        // 点击查看报告
        toLook() {
            let token = 'Lyn' +  localStorage.getItem('token')
            console.log(token)
            // 判断是否为微信内置浏览器，如果是则将token加在地址栏中
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                this.$router.push({
                    path: `/home/nowReport`,
                    query: {
                        id: this.ReportId,
                        code: token
                    }
                })
                // this.$refs.openbrowser.open()
                // alert("请使用浏览器打开！");
                // location.href="https://cp.xbssc.vip"

            } else {
                this.$router.push({
                    path: `/home/nowReport`,
                    query: {
                        id: this.ReportId
                    }
                })
            }
            // localStorage.setItem('ReportId', this.ReportId)
        },
        // 中途返回
        async backHome() {
            // console.log(localStorage.getItem('path'))
            this.timeStop();
            if (this.submitStatus) {
                this.$router.push(`${localStorage.getItem('path')}`)
            } else {
                let arr = [];
                this.tableData.forEach(item => {
                    if (item.problem.finished === false && item.problem.isAnswer === true) {
                        arr.push(item);
                    }
                });
                // console.log(111)
                if (arr.length > 0) {
                    console.log('未做完!');
                    this.$confirm('当前题目未做完是否退出?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true,
                        showClose: false
                    }).then(async () => {
                        // this.$router.push('/home/evaluation')
                        this.$router.push(`${localStorage.getItem('path')}`)
                    }).catch(() => {
                        // this.$message({
                        //     type: 'info',
                        //     message: '已取消删除'
                        // });
                    });
                } else {
                    this.$confirm('当前量表已完成是否退出?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning',
                        center: true,
                        showClose: false
                    }).then(async () => {
                        // this.$router.push('/home/evaluation')
                        this.$router.push(`${localStorage.getItem('path')}`)
                    }).catch(() => {
                    });
                }
            }

        },
        //返回按钮指定到首页去
        goBack() {
            this.$router.push({ path: "/home/evaluation" });
        }
    },
    created() {
        // let info = JSON.parse(localStorage.getItem('examinationInfo'));
        this.gaugeInfo = JSON.parse(localStorage.getItem('GaugeInfo'));
        
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        // this.userId = userInfo.id;
        
        this.planInfo = JSON.parse(localStorage.getItem('planInfo'));
        // console.log(this.planInfo);
        // console.log(this.gaugeInfo);
        
        if (localStorage.getItem('patientId') !== 'null' && localStorage.getItem('patientId')) {
            this.userId = localStorage.getItem('patientId')
        } else {
            this.userId = JSON.parse(localStorage.getItem('userInfo')).id;
        }
        if (this.planInfo.isMerge === 0) {
            this.getProblemList();
            this.gaugeName = this.gaugeInfo.name;
        } else {
            console.log('合并量表')
            this.getList(this.planInfo);
            this.gaugeName = this.planInfo.name;
        }
        // console.log(this.userId);
        // console.log(localStorage.get)
    },
    beforeDestroy() {
        this.timeStop();
    },
    //页面销毁时，取消监听
    destroyed() {
        this.timeStop();
        window.removeEventListener("popstate", this.goBack, false);
    },
    mounted() {
        //监听浏览器返回
        if (window.history) {
            history.pushState(null, "", document.URL); //这里有没有都无所谓，最好是有以防万一
            window.addEventListener("popstate", this.goBack, false); // 回退时执行goback方法
        }
    }

}
</script>

<style lang="scss" scoped>
.examination {
    // box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);
    height: 100vh;
    overflow: hidden;


    .top {
        padding: 0 10px;
        box-sizing: border-box;
        width: 100%;
        background: var(--custom-color);
        // box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1700);
        box-shadow: 0px 2px 3px 0px rgba(90,0,29,0.35);
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        padding: 0px 20px;
        //  div{
        //   width: 30%;
        //   // text-align: center;
        // }

        .left {
            // line-height: 45px;
            cursor: pointer;

            i {
                font-weight: 800;
                font-size: 28px;
            }
        }

        .right {
            // line-height: 45px;
            display: flex;
            align-items: center;

            span {
                display: inline-block;
                margin-right: 5px;
            }

            i {
                // font-weight: 800;
                font-size: 24px;
            }
        }

        .center {
            span {
                font-size: 16px;
            }
        }
    }

    .region {
        box-sizing: border-box;
        overflow-y: scroll;
        margin-top: 3px;

        .mainContent {
            padding-top: 20px;
            background: white;
            box-shadow: 0px 2px 4px 0px rgba(229,0,74,0.3);

        }

        .submitBtn {
            margin-top: 30px;
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 40px;
            width: 100%;
            background: var(--custom-color);
            border-radius: 4px;
            color: white;

            span {
                display: inline-block;
                width: 50%;
                box-sizing: border-box;
                height: 80%;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 14px;
                // line-height: 100%;
            }

            .look {
                border-left: 1px solid #FFFFFF;
            }
        }

        .rg.submit {
            display: flex;
            flex-wrap: wrap;

            .img {
                width: 100%;
                padding-top: 100px;
                text-align: center;

                p {
                    font-size: 14px;
                }

                // height: 260px;
            }

            // .submitBtn {
            //     width: 100%;
            //     box-sizing: border-box;
            //     display: flex;
            //     justify-content: space-between;
            //     margin-top: 30px;

            //     button {
            //         cursor: pointer;
            //         width: 49%;
            //         border: none;
            //         height: 40px;
            //         background: var(--custom-color);
            //         border-radius: 4px;
            //         color: white;
            //     }

            //     // background: var(--custom-color);
            //     // border-radius: 0px 4px 4px 0px;
            //     // color: white;
            //     // line-height: 40px;

            // }
        }

        .rg {
            width: 80%;
            margin: auto;
            background: white;

            img {

                height: 100px;
                width: 100px;
            }

            .choose {
                // background: #bbf4ff;
                background: rgba($color: #6FCFCC, $alpha: 0.1);
                // border-radius: 4px;
                // opacity: 0.5;
                // color: #7D7D7D;
            }

            .progress {
                display: flex;
                flex-wrap: nowrap;
                align-items: center;
                justify-content: space-around;


                .el-slider {
                    width: 80%;
                    flex: 1;
                }

                span {
                    display: inline-block;
                    width: 60px;
                    font-size: 14px;
                    // text-align: center;
                }

                .one {
                    text-align: left;
                }

                .two {
                    text-align: right;
                }
            }

            .examinationContent {
                margin-top: 20px;

                .question {
                    font-size: 18px;
                    font-weight: 600;
                    color: #303030;
                }

                .answer {
                    margin-top: 20px;
                    cursor: pointer;

                    span {
                        display: block;
                        padding: 10px 20px;
                        // height: 40px;
                        // background: #FFFFFF;
                        border-radius: 4px;
                        border: 1px solid #EEEEEE;
                        padding-left: 20px;
                        box-sizing: border-box;
                        margin-bottom: 10px;
                    }

                    // height: 40px;


                    span {
                        // line-height: 40px;
                        font-size: 14px;
                    }
                }
            }

            .btn {
                margin-top: 20px;
                padding-bottom: 20px;

                button {
                    width: 50%;
                    height: 40px;
                    background: var(--custom-color);
                    border-radius: 4px;
                    border: none;
                    color: white;
                    font-size: 14px;
                    box-sizing: border-box;

                    &:first-child {
                        background: white;
                        border: 1px solid var(--custom-color);
                        color: var(--custom-color);
                    }
                }
            }
        }

        p {
            margin: 0;
            padding: 0;

        }

    }
}

@media screen and (min-width: 751PX) {
    .top {
        height: 80px;
    }

    .region {
        width: 100%;
        background-color: var(--theme-color);
        // background-image: url('../../assets/home/backgroundImage.png');
        background-repeat: no-repeat;
        background-position: center;
        background-attachment: fixed;

        .mainContent {
            height: 100%;
            height: calc(100vh - 80px);
            overflow-y: scroll;
            margin: auto;
            width: 900px;
            background-color: white;
        }

        // .rg {
        //     height: calc(100vh - 80px);
        // }

        // height: 100%;
        // background: white;


        .rg {
            box-sizing: border-box;
        }
    }
}

@media screen and (max-width: 750PX) {
    .top {
        height: 45px;
    }
    
    .region {
        width: 100%;

        .mainContent {
            height: calc(100vh - 45px);
            margin: auto;
            width: 100%;
            background-color: white;
        }

        // height: 100vh;
    }
}
</style>